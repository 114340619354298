.nav {
  position: fixed;
  width: 90vw;
  height: 100px;
  padding: 0 5vw;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: #222222;
  border-bottom: .5px solid rgb(235, 235, 235);
  /* box-shadow: 0px 0px 5px #e7e7e7; */
}

.nav_icon {
  display: flex;
}

.nav_icon > img {
  height: 60px;
  margin: 20px 0;
}

.nav_icon {
  text-decoration: none;
}

.nav_icon > div {
  margin: 35px 0 25px 20px;
}

.nav_icon > div > h5 {
  line-height: 20px;
  width: 150px;
  color: #222222;
  font-size: 14px;
  text-align: left;
}

.nav_icon > div > p {
  width: 150px;
  line-height: 20px;
  color: #222222;
  text-align: left;
  font-size: 14px;
}

.nav_text {
  display: flex;
  width: 600px;
  justify-content: space-between;
  text-decoration: none;
}

.nav_text > a {
  text-decoration: none;
}

.nav_text > a > p {
  line-height: 40px;
  margin: 30px 0;
  color: #222222;
  font-size: 14px;
  font-weight: bold;
}

.nav_text > a > button {
  border: none;
  margin: 25px 0;
  width: 150px;
  height: 50px;
  line-height: 50px;
  background-color: #122e6b;
  color: white;
  font-weight: bold;
  line-height: 40px;
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .nav {
    position: fixed;
    width: 90vw;
    padding: 0 5vw;
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  .nav {
    position: fixed;
    width: 90vw;
    padding: 0 5vw;
  }
  
  .nav_text {
    width: 500px;
  }

  .nav_icon > h3 {
    display: none;
  }
}

@media (max-width: 700px) and (min-width: 450px) {
  .nav {
    position: fixed;
    width: 90vw;
    padding: 0 5vw;
  }

  .nav_text {
    width: 150px;
  }

  .nav_icon > h3 {
    display: none;
  }

  .nav_text > a > p {
    display: none;
  }

  .nav_service {
    display: none;
  }
}

@media (max-width: 450px)  {
  .nav_icon > div {
    display: none;
  }

  .nav {
    width: 90vw;
    padding: 0 5vw;
  }

  .nav_text {
    width: 150px;
  }

  .nav_text > a > p {
    display: none;
  }

  .nav_icon > h3 {
    display: none;
  }
}






