.Home_title {
  margin-top: 120px;
}

.Home_title > h5 {
  font-size: 15px;
  color: #6a6a6a;
  text-align: center;
}

.Home_title > h4 {
  font-size: 40px;
  color: #373737;
  text-align: center;
  font-weight: bold;
}

.home-header-space {
  border-top: 1px solid rgb(170, 170, 170);
  color: #373737;
}

.home-header-space > h4 {
  margin: 30px 0;
  font-size: 15px;
}

.flow_point {
  width: 100vw;
  padding: 1px 0 100px;
  margin: 50px 0 0;
  background-color: #f6f6f6;
}

.SB_bottom {
  width: 100vw;
  background-color: #122e6b;
  color: white;
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
  padding: 50px 0;
}