@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&display=swap);
body {
  margin: 0;
  font-family: 'Noto Sans JP', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  margin: 0;
  padding: 0;
  font-family: "Hiragino Kaku Gothic ProN", sans-serif, 'メイリオ', 'Meiryo',;
  outline: none;
  scroll-behavior: smooth;
}

.App {
  text-align: center;
  background-color: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Home_title {
  margin-top: 120px;
}

.Home_title > h5 {
  font-size: 15px;
  color: #6a6a6a;
  text-align: center;
}

.Home_title > h4 {
  font-size: 40px;
  color: #373737;
  text-align: center;
  font-weight: bold;
}

.home-header-space {
  border-top: 1px solid rgb(170, 170, 170);
  color: #373737;
}

.home-header-space > h4 {
  margin: 30px 0;
  font-size: 15px;
}

.flow_point {
  width: 100vw;
  padding: 1px 0 100px;
  margin: 50px 0 0;
  background-color: #f6f6f6;
}

.SB_bottom {
  width: 100vw;
  background-color: #122e6b;
  color: white;
  font-size: 30px;
  font-weight: bold;
  line-height: 30px;
  padding: 50px 0;
}
.nav {
  position: fixed;
  width: 90vw;
  height: 100px;
  padding: 0 5vw;
  z-index: 3;
  display: flex;
  justify-content: space-between;
  background-color: white;
  color: #222222;
  border-bottom: .5px solid rgb(235, 235, 235);
  /* box-shadow: 0px 0px 5px #e7e7e7; */
}

.nav_icon {
  display: flex;
}

.nav_icon > img {
  height: 60px;
  margin: 20px 0;
}

.nav_icon {
  text-decoration: none;
}

.nav_icon > div {
  margin: 35px 0 25px 20px;
}

.nav_icon > div > h5 {
  line-height: 20px;
  width: 150px;
  color: #222222;
  font-size: 14px;
  text-align: left;
}

.nav_icon > div > p {
  width: 150px;
  line-height: 20px;
  color: #222222;
  text-align: left;
  font-size: 14px;
}

.nav_text {
  display: flex;
  width: 600px;
  justify-content: space-between;
  text-decoration: none;
}

.nav_text > a {
  text-decoration: none;
}

.nav_text > a > p {
  line-height: 40px;
  margin: 30px 0;
  color: #222222;
  font-size: 14px;
  font-weight: bold;
}

.nav_text > a > button {
  border: none;
  margin: 25px 0;
  width: 150px;
  height: 50px;
  line-height: 50px;
  background-color: #122e6b;
  color: white;
  font-weight: bold;
  line-height: 40px;
}

@media (max-width: 1300px) and (min-width: 1000px) {
  .nav {
    position: fixed;
    width: 90vw;
    padding: 0 5vw;
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  .nav {
    position: fixed;
    width: 90vw;
    padding: 0 5vw;
  }
  
  .nav_text {
    width: 500px;
  }

  .nav_icon > h3 {
    display: none;
  }
}

@media (max-width: 700px) and (min-width: 450px) {
  .nav {
    position: fixed;
    width: 90vw;
    padding: 0 5vw;
  }

  .nav_text {
    width: 150px;
  }

  .nav_icon > h3 {
    display: none;
  }

  .nav_text > a > p {
    display: none;
  }

  .nav_service {
    display: none;
  }
}

@media (max-width: 450px)  {
  .nav_icon > div {
    display: none;
  }

  .nav {
    width: 90vw;
    padding: 0 5vw;
  }

  .nav_text {
    width: 150px;
  }

  .nav_text > a > p {
    display: none;
  }

  .nav_icon > h3 {
    display: none;
  }
}







.header {
  position: relative;
  width: 100vw;
  background-color: #F4F7FF;
  padding: 100px 0 60px;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 500px;
}

.circle_1 {
  position: absolute;
  top: 130px;
  left: 100px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #DF953D;
}

.circle_2 {
  position: absolute;
  top: 250px;
  left: 200px;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background-color: #DF953D;
}

.circle_3 {
  position: absolute;
  top: 130px;
  left: 1000px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #DF953D;
}

.circle_4 {
  position: absolute;
  top: 260px;
  left: 1200px;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #DF953D;
}

.header_text {
  position: absolute;
  width: 100vw;
  z-index: 2;
}

.header_text > h3 {
  color: #222222;
  font-size: 20px;
  margin-top: 70px;
}

.header_text > h2 {
  color: #122e6b;
  font-size: 50px;
}

.header_box {
  width: 60vw;
  background-color: white;
  border-radius: 5px;
  margin: 40px auto 0;
  padding: 30px 0px;
  box-shadow: 0px 0px 2px 2px rgb(238, 238, 238);
}

.header_box > h1 {
  font-size: 20px;
  padding: 0 20px;
  color: #222222;
  margin-bottom: 20px;
}

.header_box > h2 {
  font-size: 15px;
  padding: 0 20px;
  color: #222222;
}

.header_point {
  display: flex;
  width: 60vw;
  justify-content: space-between;
  margin: 0 auto;
}

.header_point > div {
  width: 19vw;
  height: 50px;
  border-radius: 5px;
  background-color: #DF953D;
  line-height: 50px;
  color: white;
  margin-top: 30px;
  box-shadow: 0px 0px 2px 2px rgb(238, 238, 238);
}


@media (max-width: 1300px) and (min-width: 1000px) {
  .header_box {
    width: 70vw;
  }

  .header_point {
    width: 70vw;
  }

  .header_point > div {
    width: 22vw;
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  
  .header_box {
    width: 90vw;
  }

  .header_point {
    width: 90vw;
  }

  .header_point > div {
    width: 28vw;
  }

  .circle_1 {
    display: none;
  }
  .circle_2 {
    display: none;
  }
  .circle_3 {
    display: none;
  }
  .circle_4 {
    display: none;
  }
}

@media (max-width: 700px) and (min-width: 450px) {

  .header_box {
    width: 90vw;
  }

  .header_point {
    width: 90vw;
    /* display: block; */
  }

  .header_point > div {
    width: 28vw;
  }

  .circle_1 {
    display: none;
  }
  .circle_2 {
    display: none;
  }
  .circle_3 {
    display: none;
  }
  .circle_4 {
    display: none;
  }
}

@media (max-width: 450px) and (min-width: 0px) {

  .header {
    height: 600px;
  }

  .header_text > h3 {
    font-size: 15px;
    margin-top: 70px;
  }

  .header_text > h2 {
    font-size: 30px;
  }
  
  .header_box {
    width: 90vw;
    margin: 40px auto 50px;
  }
  
  .header_box > h1 {
    font-size: 15px;
    padding: 0 20px;
    color: #222222;
  }
  
  .header_box > h2 {
    font-size: 13px;
    padding: 10px 20px;
    color: #222222;
  }
  
  .header_box > h5 > span {
    display: none;
  }

  .header_point {
    width: 90vw;
    display: block;
  }

  .header_point > div {
    /* width: 28vw; */
    width: 90vw;
    margin-top: 10px;
  }

  .circle_1 {
    display: none;
  }
  .circle_2 {
    display: none;
  }
  .circle_3 {
    display: none;
  }
  .circle_4 {
    display: none;
  }
}

/* ================================== */

.header_image_top {
  padding-top: 100px;
  position: relative;
  height: 100vh;
  background-color: #f6f6f6;
  overflow: hidden;
}

.header_image {
  position: absolute;
  margin: 6vh 0 5vh 5vw;
  width: 95vw;
  height: 70vh;
  background-image: url(/static/media/head-img.73e1f7bd.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.header_image_top > h4 {
  margin: 24vh 10vw 0;
  position: absolute;
  font-size: 55px;
  color: white;
  text-align: left;
  text-shadow: 0px 0px 5px #444444;
}

.header_image_top > p {
  position: absolute;
  margin: 55vh 10vw 0;
  font-size: 15px;
  text-align: left;
  color: white;
  text-shadow: 0px 0px 5px #444444;
}

.header_bottom {
  /* width: 75vw; */
  /* height: 75px; */
  padding: 0 2.5vw;
  border-radius: 3px;
  background-color: white;
  position: absolute;
  margin: 72vh 0 0 10vw;
  box-shadow: 0px 0px 2px #a7a7a7;
  display: flex;
  justify-content: space-between;
}

.header_bottom > div {
  width: 23vw;
  min-width: 350px;
  /* height: 55px; */
  margin: 10px 0px;
  display: flex;
}

.header_bottom > div > div {
  width: 55px;
  height: 55px;
  background-color: #122e6b;
  border-radius: 3px;
}

.header_bottom_icons {
  width: 20px;
  height: 20px;
  margin: 17.5px;
  color: white;
}

.header_bottom > div > h4 {
  margin: 15px 0 15px 20px;
  line-height: 25px;
  color: #373737;
}

@media (max-width: 650px) and (min-width: 450px) {
  .header_image_top {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    min-height: 100vh;
    background-color: #f6f6f6;
    overflow: hidden;
  }

  .header_bottom {
    display: block;
    padding-bottom: 10px;
    margin: 72vh 13vw 0;
  }

  .header_bottom > div {
    margin: 10px auto 0;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .header_image_top {
    padding-top: 100px;
    padding-bottom: 30px;
    position: relative;
    height: 90vh;
    background-color: #f6f6f6;
    overflow: hidden;
  }

  .header_bottom {
    display: block;
    padding-bottom: 10px;
    margin: 72vh auto 0;
  }

  .header_bottom > div {
    margin: 10px auto 0;
  }

  .header_image_top > h4 {
    margin: 20vh 10vw 0;
    font-size: 40px;
  }

  .header_image_top > p {
    margin: 40vh 10vw 0;
    font-size: 13px;
    text-align: left;
    color: white;
    width: 80vw;
    text-shadow: 0px 0px 5px #444444;
  }

  .header_image_top > p > br {
    display: none;
  }

  .header_bottom {
    width: 80vw;
    margin: 55vh 10vw 0;
  }
}




.Home_service_content {
  width: 90vw;
  margin: 70px 5vw 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.Home_service_content > div {
  width: 360px;
  height: 360px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px -2px gray;
  margin-bottom: 100px;
}

.Home_service_content > div > img {
  width: 360px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
  z-index: 1000;
}

.Home_service_content > div > h4 {
  width: 300px;
  margin: 0 30px;
  font-size: 20px;
  color: #373737;
  text-align: left;
  padding-top: 10px;
}

.Home_service_content > div > p {
  font-size: 13px;
  width: 300px;
  color: #373737;
  margin: 0 30px;
  text-align: left;
  margin-top: 15px;
}


.home_flow {
  width: 100vw;
  padding: 1px 0 100px;
  background-color: #F4F7FF;
  margin: 100px 0;
}

.home_flow_content {
  width: 80vw;
  margin: 0 10vw;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.home_flow_content_box {
  width: 250px;
  height: 380px;
  border: 1px solid #cfcfcf;
  margin-top: 50px;
  border-radius: 10px;
  background-color: white;
}

.home_flow_content > h4 {
  margin-top: 50px;
  line-height: 200px;
  font-size: 20px;
  color: #373737;
}

.home_flow_content_box > h5 {
  font-size: 20px;
  margin-top: 20px;
  color: #122e6b;
}

.home_flow_content_border {
  width: 15px;
  height: 2px;
  background-color: #122e6b;
  margin: 0 auto;
}

.home_flow_content_box > h4 {
  font-size: 18px;
  color: #373737;
}

.home_flow_content_box > p {
  font-size: 12px;
  color: #373737;
  margin: 10px 10px 0;
}

.home_flow_content_iconc_box {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background-color: #122e6b;
  margin: 25px auto;
}

.home_flow_icon {
  width: 50px;
  height: 50px;
  margin: 15px 0 0px;
  color: #ffffff;
}

.home_flow_content_title {
  width: 230px;
  justify-content: space-between;
  display: flex;
  margin: 30px 10px 35px;
}

.home_flow_content_title > div {
  width: 50px;
  height: 1.5px;
  background-color: #122e6b;
  margin-top: 12px;
}

.home_flow_content_title > h4 {
  font-size: 16px;
  left: 25px;
  color: #373737;
}

.home_flow_content_desc {
  width: 180px;
  display: flex;
  margin: 25px auto 0;
  justify-content: space-between;
}

.home_flow_content_desc_check {
  width: 14px;
  height: 14px;
  color: #122e6b;
}

.home_flow_content_desc > p {
  width: 140px;
  font-size: 14px;
  line-height: 14px;
  color: #373737;
  text-align: left;
}





.Home_point_title {
  margin: 100px auto 0;
  font-size: 30px;
  color: rgb(85, 85, 85);
}

.home_point_1 {
  background-color: white;
  width: 80vw;
  margin: 100px 10vw 0;
  height: 300px;
  box-shadow: 0px 0px 5px rgb(228, 228, 228);
  display: flex;
  border-radius: 10px;
}

/* .home_point_1 > h5 {
  width: 20vw;
  font-size: 100px;
  color: #5483e8;
  line-height: 300px;
} */

.home_point_icon {
  width: calc(150px);
  height: 150px;
  padding: 75px calc((20vw - 150px) / 2);
}

.home_point_1 > div > h4 {
  width: 60vw;
  font-size: 30px;
  color: #373737;
  margin-top: 100px;
  text-align: left;
}

.home_point_1 > div > p {
  width: 50vw;
  font-size: 15px;
  color: #373737;
  text-align: left;
}

@media (max-width: 450px) and (min-width: 0px) {
  .home_point_1 {
    display: block;
    width: 90vw;
    margin: 100px 5vw 0;
    height: 450px;
  }

  .home_point_icon {
    width: 40vw;
    height: 40vw;
    padding: 40px 25vw;
  }

  .home_point_1 > div > h4 {
    font-size: 30px;
    color: #373737;
    margin-top: 0px;
    width: 90vw;
    text-align: center;
  }
  
  .home_point_1 > div > p {
    width: 80vw;
    text-align: center;
    font-size: 15px;
    color: #373737;
    padding: 10px 5vw 0;
  }
}



.SQ {
  width: 80vw;
  margin: 100px auto;
}

.SQ > div {
  margin-top: 50px;
}

.SQ > div > div > div {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background-color: #122e6b;
  color: white;
  font-weight: bold;
  line-height: 55px;
  text-align: center;
  font-size: 25px;
}

.SQ > div > div {
  display: flex;
}

.SQ > div > div > h4 {
  font-size: 25px;
  line-height: 60px;
  margin-left: 50px;
  text-align: left;
}

.SQ > div > p {
  font-size: 15px;
  margin: 10px 0 0 110px;
  text-align: left;
  font-weight: 400;
  line-height: 35px;
}

@media  (max-width: 700px) and (min-width: 600px) {
  .SQ {
    width: 90vw;
    margin: 100px auto;
  }

  .SQ > div > div > h4 {
    margin-left: 20px;
  }
  
  .SQ > div > p {
    margin: 10px 0 0 80px;
  }
}

@media  (max-width: 600px) and (min-width: 450px) {
  .SQ {
    width: 90vw;
    margin: 100px auto;
  }

  .SQ > div > div > h4 {
    width: 350px;
    margin-left: 20px;
  }

  .SQ > div > p {
    margin: 10px 0 0 80px;
  }
}

@media  (max-width: 450px) and (min-width: 0px) {
  .SQ {
    width: 90vw;
    margin: 100px auto;
  }

  .SQ > div > div > h4 {
    margin-left: 20px;
    font-size: 18px;
    width:300px;
  }

  .SQ > div > p {
    margin: 10px 0 0 80px;
  }
}

.Home_bottom {
  width: 70vw;
  padding: 100px 15vw;
  display: flex;
  justify-content: space-between;
  /* background-color: #F4F7FF; */
  background-color: #f6f6f6;
  /* background-color: #122e6b; */
}

.Home_bottom_company {
  width: 400px;
  height: 750px;
}

.Home_bottom_company > h4 {
  width: 360px;
  text-align: left;
  margin: 20px 20px 10px;
  color: #373737;
}

.Home_bottom_company_info {
  width: 400px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border: 1px solid #DFDFDF;
  border-radius: 5px;
  background-color: white;
}

.Home_bottom_company_info > h4 {
  width: 360px;
  text-align: left;
  margin: 20px 20px -20px;
  color: #373737;
}

.Home_bottom_company_info > div {
  display: flex;
  margin: 40px 20px 0;
  width: 360px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 10px;
  justify-content: space-between;
}

.Home_company_content_info > div > h4 {
  color: #373737;
  line-height: 15px;
  font-size: 15px;
}

.Home_company_content_info > div > p {
  color: #373737;
  line-height: 13px;
  font-size: 15px;
  /* width: 200px; */
  text-align: left;
}

.Home_bottom_company_photo {
  width: 400px;
  height: 220px;
  background-image: url(/static/media/photo.0912c277.png);
  background-size: cover;
  border-radius: 5px;
}

.Home_bottom_company {
  text-align: left;
  margin-bottom: 10px;
}

.home_form {
  border: 1px solid rgb(208, 208, 208);
}

.Home_company_content_info_top {
  width: 400px;
  height: 300px;
}

.Home_company_content_info_bottom {
  width: 400px;
  height: 300px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .Home_bottom {
    width: 80vw;
    padding: 100px 10vw;
    display: flex;
    justify-content: space-between;
    background-color: #F4F7FF;
  }
}

@media (max-width: 1000px) and (min-width: 900px) {
  .Home_bottom {
    width: 90vw;
    padding: 100px 5vw;
    display: flex;
    justify-content: space-between;
    background-color: #F4F7FF;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .Home_bottom {
    display: block;
    width: 90vw;
    padding: 100px 5vw;
  }

  .Home_bottom_company {
    width: 90vw;
    height: 600px;
  }

  .Home_bottom_company > h4 {
    width: 300px;
    text-align: left;
    margin: 20px 20px 10px;
    color: #373737;
  }

  .Home_bottom_company_info {
    width: 90vw;
    margin-bottom: 40px;
    padding-bottom: 30px;
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    background-color: white;
  }

  .Home_bottom_company_info > h4 {
    width: 300px;
    text-align: left;
    margin: 20px 0px -20px 20px;
    color: #373737;
  }

  .Home_bottom_company_info > div {
    display: flex;
    margin: 40px 20px 0;
    width: 80vw;
    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 10px;
    justify-content: space-between;
  }

  .Home_company_content_info > div > h4 {
    color: #373737;
    line-height: 15px;
    font-size: 15px;
  }

  .Home_company_content_info > div > p {
    color: #373737;
    line-height: 15px;
    font-size: 15px;
    width: 250px;
    text-align: left;
  }

  .Home_bottom_company_photo {
    width: 90vw;
    height: 220px;
    background-image: url(/static/media/photo.0912c277.png);
    background-size: cover;
    border-radius: 5px;
  }

  .Home_bottom_company {
    text-align: left;
    margin-bottom: 10px;
  }

  .home_form {
    border: 1px solid rgb(208, 208, 208);
  }

  .Home_company_content_info_top {
    width: 90vw;
    height: 300px;
  }

  .Home_company_content_info_bottom {
    width: 90vw;
    height: 300px;
  }
}
/* 問い合わせ */

.SH_contact {
  width: 400px;
  height: 670px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #DFDFDF;
}

.SH_contact > h4 {
  color: #CF8329;
  font-size: 20px;
  padding-top: 25px;
}

.SH_contact_title_name {
  display: flex;
  height: 20px;
  margin: 30px 20px 0;
  justify-content: space-between;
}

.SH_contact_title_name > div {
  display: flex;
  justify-content: space-between;
  width: 165px;
  height: 20px;
}

.SH_contact_title_name > div > div {
  background-color: #FDD09B;
  padding: 0 5px;
  border-radius: 3px;
  line-height: 20px;
}

.SH_contact_title_name > div > h5 {
  line-height: 20px;
  color: #373737;
  font-weight: 600;
}

.SH_contact_title_name > div > div > h5 {
  font-size: 13px;
  line-height: 20px;
  color: #CF8329;
}

.SH_contact_input_name {
  display: flex;
  height: 40px;
  margin: 10px 20px 0;
  justify-content: space-between;
}

.SH_contact_input_name > input {
  width: 165px;
  height: 40px;
  border: none;
  border: 1px solid #DDDDDD;
  background-color: #F2F2F2;
  text-indent: 1em;
}


.SH_contact_title_company {
  width: auto;
  justify-content: space-between;
  display: flex;
  margin: 15px 20px 0;
}

.SH_contact_title_company > div {
  /* background-color: #630e24; */
  padding: 0 5px;
  border-radius: 3px;
  line-height: 20px;
}

.SH_contact_title_company > div > h5 {
  font-size: 13px;
  line-height: 20px;
  color: #CF8329;
}

.SH_contact_input_company > input {
  width: 360px;
  height: 40px;
  border: none;
  border: 1px solid #DDDDDD;
  background-color: #F2F2F2;
  text-indent: 1em;
  margin: 10px 20px 0;
}

.SH_contact_inpute_text > textarea {
  width: 330px;
  height: 100px;
  border: none;
  border: 1px solid #DDDDDD;
  background-color: #F2F2F2;
  /* text-indent: 1em; */
  margin: 10px 20px 0;
  resize: none;
  padding: 10px 15px;
}

.SH_contact > button {
  width: 200px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #CF8329;
  color: white;
  border: none;
  margin-top: 20px;
  font-weight: bold;
}

@media (max-width: 450px) and (min-width: 0px) {
  .SH_contact {
    width: 90vw;
    height: 670px;
    background-color: white;
    margin: 0 auto 50px;
  }
  
  .SH_contact > h4 {
    color: #CF8329;
    font-size: 23px;
    padding-top: 25px;
  }
  
  .SH_contact_title_name {
    display: flex;
    height: 20px;
    margin: 30px 20px 0;
    justify-content: space-between;
  }
  
  .SH_contact_title_name > div {
    display: flex;
    justify-content: space-between;
    width: 140px;
    height: 20px;
  }
  
  .SH_contact_title_name > div > div {
    background-color: #FDD09B;
    padding: 0 5px;
    border-radius: 3px;
    line-height: 20px;
  }
  
  .SH_contact_title_name > div > div > h5 {
    font-size: 13px;
    line-height: 20px;
    color: #CF8329;
  }
  
  .SH_contact_input_name {
    display: flex;
    height: 40px;
    margin: 10px 20px 0;
    justify-content: space-between;
  }
  
  .SH_contact_input_name > input {
    width: 140px;
    height: 40px;
    border: none;
    border: 1px solid #DDDDDD;
    background-color: #F2F2F2;
    text-indent: 1em;
    border-radius: 0;
  }
  
  .SH_contact_title_company {
    width: auto;
    justify-content: space-between;
    display: flex;
    margin: 30px 20px 0;
  }
  
  .SH_contact_title_company > div {
    background-color: #FDD09B;
    padding: 0 5px;
    border-radius: 3px;
    line-height: 20px;
  }
  
  .SH_contact_title_company > div > h5 {
    font-size: 13px;
    line-height: 20px;
    color: #CF8329;
  }
  
  .SH_contact_input_company > input {
    width: calc(90vw - 40px);
    height: 40px;
    border: none;
    border: 1px solid #DDDDDD;
    background-color: #F2F2F2;
    text-indent: 1em;
    margin: 10px 20px 0;
  }
  
  .SH_contact_inpute_text > textarea {
    width: calc(90vw - 40px);
    height: 150px;
    border: none;
    border: 1px solid #DDDDDD;
    background-color: #F2F2F2;
    text-indent: 1em;
    margin: 10px 20px 0;
    resize: none;
  }
  
  .SH_contact > button {
    width: 200px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    font-size: 15px;
    background-color: #CF8329;
    color: white;
    border: none;
    margin-top: 20px;
    font-weight: bold;
  }
}

/* ================== */

.send_mail {
  width: 400px;
  height: 670px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #DFDFDF;
}

.send_mail > img {
  width: 200px;
  height: 200px;
  margin-top: 180px;
  box-shadow: 0px 0px 3px #c1c1c1;
  border-radius: 100px;
}

.send_mail > h6 {
  color: #CF8329;
  font-size: 15px;
  margin-top: 30px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .send_mail {
    width: 90vw;
    height: 670px;
    background-color: white;
    margin: 0 auto 50px;
  }
}

