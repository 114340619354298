.header {
  position: relative;
  width: 100vw;
  background-color: #F4F7FF;
  padding: 100px 0 60px;
  background-repeat: no-repeat;
  overflow: hidden;
  height: 500px;
}

.circle_1 {
  position: absolute;
  top: 130px;
  left: 100px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #DF953D;
}

.circle_2 {
  position: absolute;
  top: 250px;
  left: 200px;
  width: 70px;
  height: 70px;
  border-radius: 100px;
  background-color: #DF953D;
}

.circle_3 {
  position: absolute;
  top: 130px;
  left: 1000px;
  width: 50px;
  height: 50px;
  border-radius: 100px;
  background-color: #DF953D;
}

.circle_4 {
  position: absolute;
  top: 260px;
  left: 1200px;
  width: 30px;
  height: 30px;
  border-radius: 100px;
  background-color: #DF953D;
}

.header_text {
  position: absolute;
  width: 100vw;
  z-index: 2;
}

.header_text > h3 {
  color: #222222;
  font-size: 20px;
  margin-top: 70px;
}

.header_text > h2 {
  color: #122e6b;
  font-size: 50px;
}

.header_box {
  width: 60vw;
  background-color: white;
  border-radius: 5px;
  margin: 40px auto 0;
  padding: 30px 0px;
  box-shadow: 0px 0px 2px 2px rgb(238, 238, 238);
}

.header_box > h1 {
  font-size: 20px;
  padding: 0 20px;
  color: #222222;
  margin-bottom: 20px;
}

.header_box > h2 {
  font-size: 15px;
  padding: 0 20px;
  color: #222222;
}

.header_point {
  display: flex;
  width: 60vw;
  justify-content: space-between;
  margin: 0 auto;
}

.header_point > div {
  width: 19vw;
  height: 50px;
  border-radius: 5px;
  background-color: #DF953D;
  line-height: 50px;
  color: white;
  margin-top: 30px;
  box-shadow: 0px 0px 2px 2px rgb(238, 238, 238);
}


@media (max-width: 1300px) and (min-width: 1000px) {
  .header_box {
    width: 70vw;
  }

  .header_point {
    width: 70vw;
  }

  .header_point > div {
    width: 22vw;
  }
}

@media (max-width: 1000px) and (min-width: 700px) {
  
  .header_box {
    width: 90vw;
  }

  .header_point {
    width: 90vw;
  }

  .header_point > div {
    width: 28vw;
  }

  .circle_1 {
    display: none;
  }
  .circle_2 {
    display: none;
  }
  .circle_3 {
    display: none;
  }
  .circle_4 {
    display: none;
  }
}

@media (max-width: 700px) and (min-width: 450px) {

  .header_box {
    width: 90vw;
  }

  .header_point {
    width: 90vw;
    /* display: block; */
  }

  .header_point > div {
    width: 28vw;
  }

  .circle_1 {
    display: none;
  }
  .circle_2 {
    display: none;
  }
  .circle_3 {
    display: none;
  }
  .circle_4 {
    display: none;
  }
}

@media (max-width: 450px) and (min-width: 0px) {

  .header {
    height: 600px;
  }

  .header_text > h3 {
    font-size: 15px;
    margin-top: 70px;
  }

  .header_text > h2 {
    font-size: 30px;
  }
  
  .header_box {
    width: 90vw;
    margin: 40px auto 50px;
  }
  
  .header_box > h1 {
    font-size: 15px;
    padding: 0 20px;
    color: #222222;
  }
  
  .header_box > h2 {
    font-size: 13px;
    padding: 10px 20px;
    color: #222222;
  }
  
  .header_box > h5 > span {
    display: none;
  }

  .header_point {
    width: 90vw;
    display: block;
  }

  .header_point > div {
    /* width: 28vw; */
    width: 90vw;
    margin-top: 10px;
  }

  .circle_1 {
    display: none;
  }
  .circle_2 {
    display: none;
  }
  .circle_3 {
    display: none;
  }
  .circle_4 {
    display: none;
  }
}

/* ================================== */

.header_image_top {
  padding-top: 100px;
  position: relative;
  height: 100vh;
  background-color: #f6f6f6;
  overflow: hidden;
}

.header_image {
  position: absolute;
  margin: 6vh 0 5vh 5vw;
  width: 95vw;
  height: 70vh;
  background-image: url(./../../image/head-img.png);
  background-repeat: no-repeat;
  background-size: cover;
}

.header_image_top > h4 {
  margin: 24vh 10vw 0;
  position: absolute;
  font-size: 55px;
  color: white;
  text-align: left;
  text-shadow: 0px 0px 5px #444444;
}

.header_image_top > p {
  position: absolute;
  margin: 55vh 10vw 0;
  font-size: 15px;
  text-align: left;
  color: white;
  text-shadow: 0px 0px 5px #444444;
}

.header_bottom {
  /* width: 75vw; */
  /* height: 75px; */
  padding: 0 2.5vw;
  border-radius: 3px;
  background-color: white;
  position: absolute;
  margin: 72vh 0 0 10vw;
  box-shadow: 0px 0px 2px #a7a7a7;
  display: flex;
  justify-content: space-between;
}

.header_bottom > div {
  width: 23vw;
  min-width: 350px;
  /* height: 55px; */
  margin: 10px 0px;
  display: flex;
}

.header_bottom > div > div {
  width: 55px;
  height: 55px;
  background-color: #122e6b;
  border-radius: 3px;
}

.header_bottom_icons {
  width: 20px;
  height: 20px;
  margin: 17.5px;
  color: white;
}

.header_bottom > div > h4 {
  margin: 15px 0 15px 20px;
  line-height: 25px;
  color: #373737;
}

@media (max-width: 650px) and (min-width: 450px) {
  .header_image_top {
    padding-top: 100px;
    padding-bottom: 100px;
    position: relative;
    min-height: 100vh;
    background-color: #f6f6f6;
    overflow: hidden;
  }

  .header_bottom {
    display: block;
    padding-bottom: 10px;
    margin: 72vh 13vw 0;
  }

  .header_bottom > div {
    margin: 10px auto 0;
  }
}

@media (max-width: 450px) and (min-width: 0px) {
  .header_image_top {
    padding-top: 100px;
    padding-bottom: 30px;
    position: relative;
    height: 90vh;
    background-color: #f6f6f6;
    overflow: hidden;
  }

  .header_bottom {
    display: block;
    padding-bottom: 10px;
    margin: 72vh auto 0;
  }

  .header_bottom > div {
    margin: 10px auto 0;
  }

  .header_image_top > h4 {
    margin: 20vh 10vw 0;
    font-size: 40px;
  }

  .header_image_top > p {
    margin: 40vh 10vw 0;
    font-size: 13px;
    text-align: left;
    color: white;
    width: 80vw;
    text-shadow: 0px 0px 5px #444444;
  }

  .header_image_top > p > br {
    display: none;
  }

  .header_bottom {
    width: 80vw;
    margin: 55vh 10vw 0;
  }
}



