.home_flow {
  width: 100vw;
  padding: 1px 0 100px;
  background-color: #F4F7FF;
  margin: 100px 0;
}

.home_flow_content {
  width: 80vw;
  margin: 0 10vw;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.home_flow_content_box {
  width: 250px;
  height: 380px;
  border: 1px solid #cfcfcf;
  margin-top: 50px;
  border-radius: 10px;
  background-color: white;
}

.home_flow_content > h4 {
  margin-top: 50px;
  line-height: 200px;
  font-size: 20px;
  color: #373737;
}

.home_flow_content_box > h5 {
  font-size: 20px;
  margin-top: 20px;
  color: #122e6b;
}

.home_flow_content_border {
  width: 15px;
  height: 2px;
  background-color: #122e6b;
  margin: 0 auto;
}

.home_flow_content_box > h4 {
  font-size: 18px;
  color: #373737;
}

.home_flow_content_box > p {
  font-size: 12px;
  color: #373737;
  margin: 10px 10px 0;
}

.home_flow_content_iconc_box {
  width: 80px;
  height: 80px;
  border-radius: 5px;
  background-color: #122e6b;
  margin: 25px auto;
}

.home_flow_icon {
  width: 50px;
  height: 50px;
  margin: 15px 0 0px;
  color: #ffffff;
}

.home_flow_content_title {
  width: 230px;
  justify-content: space-between;
  display: flex;
  margin: 30px 10px 35px;
}

.home_flow_content_title > div {
  width: 50px;
  height: 1.5px;
  background-color: #122e6b;
  margin-top: 12px;
}

.home_flow_content_title > h4 {
  font-size: 16px;
  left: 25px;
  color: #373737;
}

.home_flow_content_desc {
  width: 180px;
  display: flex;
  margin: 25px auto 0;
  justify-content: space-between;
}

.home_flow_content_desc_check {
  width: 14px;
  height: 14px;
  color: #122e6b;
}

.home_flow_content_desc > p {
  width: 140px;
  font-size: 14px;
  line-height: 14px;
  color: #373737;
  text-align: left;
}




