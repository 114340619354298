.Home_bottom {
  width: 70vw;
  padding: 100px 15vw;
  display: flex;
  justify-content: space-between;
  /* background-color: #F4F7FF; */
  background-color: #f6f6f6;
  /* background-color: #122e6b; */
}

.Home_bottom_company {
  width: 400px;
  height: 750px;
}

.Home_bottom_company > h4 {
  width: 360px;
  text-align: left;
  margin: 20px 20px 10px;
  color: #373737;
}

.Home_bottom_company_info {
  width: 400px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border: 1px solid #DFDFDF;
  border-radius: 5px;
  background-color: white;
}

.Home_bottom_company_info > h4 {
  width: 360px;
  text-align: left;
  margin: 20px 20px -20px;
  color: #373737;
}

.Home_bottom_company_info > div {
  display: flex;
  margin: 40px 20px 0;
  width: 360px;
  border-bottom: 1px solid #DFDFDF;
  padding-bottom: 10px;
  justify-content: space-between;
}

.Home_company_content_info > div > h4 {
  color: #373737;
  line-height: 15px;
  font-size: 15px;
}

.Home_company_content_info > div > p {
  color: #373737;
  line-height: 13px;
  font-size: 15px;
  /* width: 200px; */
  text-align: left;
}

.Home_bottom_company_photo {
  width: 400px;
  height: 220px;
  background-image: url(./../../image/photo.png);
  background-size: cover;
  border-radius: 5px;
}

.Home_bottom_company {
  text-align: left;
  margin-bottom: 10px;
}

.home_form {
  border: 1px solid rgb(208, 208, 208);
}

.Home_company_content_info_top {
  width: 400px;
  height: 300px;
}

.Home_company_content_info_bottom {
  width: 400px;
  height: 300px;
}

@media (max-width: 1200px) and (min-width: 1000px) {
  .Home_bottom {
    width: 80vw;
    padding: 100px 10vw;
    display: flex;
    justify-content: space-between;
    background-color: #F4F7FF;
  }
}

@media (max-width: 1000px) and (min-width: 900px) {
  .Home_bottom {
    width: 90vw;
    padding: 100px 5vw;
    display: flex;
    justify-content: space-between;
    background-color: #F4F7FF;
  }
}

@media (max-width: 600px) and (min-width: 0px) {
  .Home_bottom {
    display: block;
    width: 90vw;
    padding: 100px 5vw;
  }

  .Home_bottom_company {
    width: 90vw;
    height: 600px;
  }

  .Home_bottom_company > h4 {
    width: 300px;
    text-align: left;
    margin: 20px 20px 10px;
    color: #373737;
  }

  .Home_bottom_company_info {
    width: 90vw;
    margin-bottom: 40px;
    padding-bottom: 30px;
    border: 1px solid #DFDFDF;
    border-radius: 5px;
    background-color: white;
  }

  .Home_bottom_company_info > h4 {
    width: 300px;
    text-align: left;
    margin: 20px 0px -20px 20px;
    color: #373737;
  }

  .Home_bottom_company_info > div {
    display: flex;
    margin: 40px 20px 0;
    width: 80vw;
    border-bottom: 1px solid #DFDFDF;
    padding-bottom: 10px;
    justify-content: space-between;
  }

  .Home_company_content_info > div > h4 {
    color: #373737;
    line-height: 15px;
    font-size: 15px;
  }

  .Home_company_content_info > div > p {
    color: #373737;
    line-height: 15px;
    font-size: 15px;
    width: 250px;
    text-align: left;
  }

  .Home_bottom_company_photo {
    width: 90vw;
    height: 220px;
    background-image: url(./../../image/photo.png);
    background-size: cover;
    border-radius: 5px;
  }

  .Home_bottom_company {
    text-align: left;
    margin-bottom: 10px;
  }

  .home_form {
    border: 1px solid rgb(208, 208, 208);
  }

  .Home_company_content_info_top {
    width: 90vw;
    height: 300px;
  }

  .Home_company_content_info_bottom {
    width: 90vw;
    height: 300px;
  }
}