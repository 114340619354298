.SQ {
  width: 80vw;
  margin: 100px auto;
}

.SQ > div {
  margin-top: 50px;
}

.SQ > div > div > div {
  width: 60px;
  height: 60px;
  border-radius: 50px;
  background-color: #122e6b;
  color: white;
  font-weight: bold;
  line-height: 55px;
  text-align: center;
  font-size: 25px;
}

.SQ > div > div {
  display: flex;
}

.SQ > div > div > h4 {
  font-size: 25px;
  line-height: 60px;
  margin-left: 50px;
  text-align: left;
}

.SQ > div > p {
  font-size: 15px;
  margin: 10px 0 0 110px;
  text-align: left;
  font-weight: 400;
  line-height: 35px;
}

@media  (max-width: 700px) and (min-width: 600px) {
  .SQ {
    width: 90vw;
    margin: 100px auto;
  }

  .SQ > div > div > h4 {
    margin-left: 20px;
  }
  
  .SQ > div > p {
    margin: 10px 0 0 80px;
  }
}

@media  (max-width: 600px) and (min-width: 450px) {
  .SQ {
    width: 90vw;
    margin: 100px auto;
  }

  .SQ > div > div > h4 {
    width: 350px;
    margin-left: 20px;
  }

  .SQ > div > p {
    margin: 10px 0 0 80px;
  }
}

@media  (max-width: 450px) and (min-width: 0px) {
  .SQ {
    width: 90vw;
    margin: 100px auto;
  }

  .SQ > div > div > h4 {
    margin-left: 20px;
    font-size: 18px;
    width:300px;
  }

  .SQ > div > p {
    margin: 10px 0 0 80px;
  }
}
