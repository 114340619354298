.Home_service_content {
  width: 90vw;
  margin: 70px 5vw 0;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.Home_service_content > div {
  width: 360px;
  height: 360px;
  border-radius: 5px;
  box-shadow: 0px 0px 4px -2px gray;
  margin-bottom: 100px;
}

.Home_service_content > div > img {
  width: 360px;
  height: 200px;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
  z-index: 1000;
}

.Home_service_content > div > h4 {
  width: 300px;
  margin: 0 30px;
  font-size: 20px;
  color: #373737;
  text-align: left;
  padding-top: 10px;
}

.Home_service_content > div > p {
  font-size: 13px;
  width: 300px;
  color: #373737;
  margin: 0 30px;
  text-align: left;
  margin-top: 15px;
}

