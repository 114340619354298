.Home_point_title {
  margin: 100px auto 0;
  font-size: 30px;
  color: rgb(85, 85, 85);
}

.home_point_1 {
  background-color: white;
  width: 80vw;
  margin: 100px 10vw 0;
  height: 300px;
  box-shadow: 0px 0px 5px rgb(228, 228, 228);
  display: flex;
  border-radius: 10px;
}

/* .home_point_1 > h5 {
  width: 20vw;
  font-size: 100px;
  color: #5483e8;
  line-height: 300px;
} */

.home_point_icon {
  width: calc(150px);
  height: 150px;
  padding: 75px calc((20vw - 150px) / 2);
}

.home_point_1 > div > h4 {
  width: 60vw;
  font-size: 30px;
  color: #373737;
  margin-top: 100px;
  text-align: left;
}

.home_point_1 > div > p {
  width: 50vw;
  font-size: 15px;
  color: #373737;
  text-align: left;
}

@media (max-width: 450px) and (min-width: 0px) {
  .home_point_1 {
    display: block;
    width: 90vw;
    margin: 100px 5vw 0;
    height: 450px;
  }

  .home_point_icon {
    width: 40vw;
    height: 40vw;
    padding: 40px 25vw;
  }

  .home_point_1 > div > h4 {
    font-size: 30px;
    color: #373737;
    margin-top: 0px;
    width: 90vw;
    text-align: center;
  }
  
  .home_point_1 > div > p {
    width: 80vw;
    text-align: center;
    font-size: 15px;
    color: #373737;
    padding: 10px 5vw 0;
  }
}


