/* 問い合わせ */

.SH_contact {
  width: 400px;
  height: 670px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #DFDFDF;
}

.SH_contact > h4 {
  color: #CF8329;
  font-size: 20px;
  padding-top: 25px;
}

.SH_contact_title_name {
  display: flex;
  height: 20px;
  margin: 30px 20px 0;
  justify-content: space-between;
}

.SH_contact_title_name > div {
  display: flex;
  justify-content: space-between;
  width: 165px;
  height: 20px;
}

.SH_contact_title_name > div > div {
  background-color: #FDD09B;
  padding: 0 5px;
  border-radius: 3px;
  line-height: 20px;
}

.SH_contact_title_name > div > h5 {
  line-height: 20px;
  color: #373737;
  font-weight: 600;
}

.SH_contact_title_name > div > div > h5 {
  font-size: 13px;
  line-height: 20px;
  color: #CF8329;
}

.SH_contact_input_name {
  display: flex;
  height: 40px;
  margin: 10px 20px 0;
  justify-content: space-between;
}

.SH_contact_input_name > input {
  width: 165px;
  height: 40px;
  border: none;
  border: 1px solid #DDDDDD;
  background-color: #F2F2F2;
  text-indent: 1em;
}


.SH_contact_title_company {
  width: auto;
  justify-content: space-between;
  display: flex;
  margin: 15px 20px 0;
}

.SH_contact_title_company > div {
  /* background-color: #630e24; */
  padding: 0 5px;
  border-radius: 3px;
  line-height: 20px;
}

.SH_contact_title_company > div > h5 {
  font-size: 13px;
  line-height: 20px;
  color: #CF8329;
}

.SH_contact_input_company > input {
  width: 360px;
  height: 40px;
  border: none;
  border: 1px solid #DDDDDD;
  background-color: #F2F2F2;
  text-indent: 1em;
  margin: 10px 20px 0;
}

.SH_contact_inpute_text > textarea {
  width: 330px;
  height: 100px;
  border: none;
  border: 1px solid #DDDDDD;
  background-color: #F2F2F2;
  /* text-indent: 1em; */
  margin: 10px 20px 0;
  resize: none;
  padding: 10px 15px;
}

.SH_contact > button {
  width: 200px;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;
  font-size: 15px;
  background-color: #CF8329;
  color: white;
  border: none;
  margin-top: 20px;
  font-weight: bold;
}

@media (max-width: 450px) and (min-width: 0px) {
  .SH_contact {
    width: 90vw;
    height: 670px;
    background-color: white;
    margin: 0 auto 50px;
  }
  
  .SH_contact > h4 {
    color: #CF8329;
    font-size: 23px;
    padding-top: 25px;
  }
  
  .SH_contact_title_name {
    display: flex;
    height: 20px;
    margin: 30px 20px 0;
    justify-content: space-between;
  }
  
  .SH_contact_title_name > div {
    display: flex;
    justify-content: space-between;
    width: 140px;
    height: 20px;
  }
  
  .SH_contact_title_name > div > div {
    background-color: #FDD09B;
    padding: 0 5px;
    border-radius: 3px;
    line-height: 20px;
  }
  
  .SH_contact_title_name > div > div > h5 {
    font-size: 13px;
    line-height: 20px;
    color: #CF8329;
  }
  
  .SH_contact_input_name {
    display: flex;
    height: 40px;
    margin: 10px 20px 0;
    justify-content: space-between;
  }
  
  .SH_contact_input_name > input {
    width: 140px;
    height: 40px;
    border: none;
    border: 1px solid #DDDDDD;
    background-color: #F2F2F2;
    text-indent: 1em;
    border-radius: 0;
  }
  
  .SH_contact_title_company {
    width: auto;
    justify-content: space-between;
    display: flex;
    margin: 30px 20px 0;
  }
  
  .SH_contact_title_company > div {
    background-color: #FDD09B;
    padding: 0 5px;
    border-radius: 3px;
    line-height: 20px;
  }
  
  .SH_contact_title_company > div > h5 {
    font-size: 13px;
    line-height: 20px;
    color: #CF8329;
  }
  
  .SH_contact_input_company > input {
    width: calc(90vw - 40px);
    height: 40px;
    border: none;
    border: 1px solid #DDDDDD;
    background-color: #F2F2F2;
    text-indent: 1em;
    margin: 10px 20px 0;
  }
  
  .SH_contact_inpute_text > textarea {
    width: calc(90vw - 40px);
    height: 150px;
    border: none;
    border: 1px solid #DDDDDD;
    background-color: #F2F2F2;
    text-indent: 1em;
    margin: 10px 20px 0;
    resize: none;
  }
  
  .SH_contact > button {
    width: 200px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    font-size: 15px;
    background-color: #CF8329;
    color: white;
    border: none;
    margin-top: 20px;
    font-weight: bold;
  }
}

/* ================== */

.send_mail {
  width: 400px;
  height: 670px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #DFDFDF;
}

.send_mail > img {
  width: 200px;
  height: 200px;
  margin-top: 180px;
  box-shadow: 0px 0px 3px #c1c1c1;
  border-radius: 100px;
}

.send_mail > h6 {
  color: #CF8329;
  font-size: 15px;
  margin-top: 30px;
}

@media (max-width: 450px) and (min-width: 0px) {
  .send_mail {
    width: 90vw;
    height: 670px;
    background-color: white;
    margin: 0 auto 50px;
  }
}
